import React, { Component } from 'react';

export class ShowFullItem extends Component {
  render() {
    const imgSrc = process.env.PUBLIC_URL;

    return (
      <div className='full-item'>
        <div>
          <img
            src={imgSrc + this.props.item.img}
            onClick={() => this.props.onShowItem(this.props.item)}
            alt={this.props.item.title}
          />
          <h2>{this.props.item.title}</h2>
          <p>{this.props.item.desc}</p>
          <b>{this.props.item.price} грн</b>
          <div className='buttons'>
            <div className='question-mark' onClick={() => window.location.href = this.props.item.url}>?</div>
            <div className='add-to-cart' onClick={() => this.props.onAdd(this.props.item)}>+</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShowFullItem;


