import React, { useEffect } from 'react';

function StepAnimation() {
    useEffect(() => {
        const steps = document.querySelectorAll('.step');

        const checkPosition = () => {
            steps.forEach(step => {
                const stepPosition = step.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;

                if (stepPosition < screenPosition) {
                    step.classList.add('visible-step');
                }
            });
        };

        window.addEventListener('scroll', checkPosition);

        return () => {
            window.removeEventListener('scroll', checkPosition);
        };
    }, []); // Виклик функції useEffect тільки після першого рендерингу

    return (
        <div className='Home-4'>
        <div className="step hiden-step">
          <img className="Home-4-step-image" src={process.env.PUBLIC_URL + '/img/Home-4-1.jpg'} alt="Home-4-1"/>
          <div className="Home-4-step-description">
            <div className="Home-4-number">1</div>
            <p className="Home-4-step-text">Знайдіть техніку за необхідними для Вас характеристиками та атрибутами.</p>
          </div>
        </div>
        <div className="step hiden-step">
          <img className="Home-4-step-image" src={process.env.PUBLIC_URL + '/img/Home-4-2.jpg'} alt="Home-4-2"/>
          <div className="Home-4-step-description">
            <div className="Home-4-number">2</div>
            <p className="Home-4-step-text">Зв'жіться з <a class="visible-link" href="https://t.me/eurostock_nt">Менеджером</a>, який допоможе Вам з вибор і оформленням замовлення.</p>
          </div>
        </div>
        <div className="step hiden-step">
          <img className="Home-4-step-image" src={process.env.PUBLIC_URL + '/img/Home-4-3.jpg'} alt="Home-4-3"/>
          <div className="Home-4-step-description">
            <div className="Home-4-number">3</div>
            <p className="Home-4-step-text">Після оформлення замовлення, розрахуйтесь та отримайте чек і гарантійний талон.</p>
          </div>
        </div> 
        <div className="step hiden-step">
          <img className="Home-4-step-image" src={process.env.PUBLIC_URL + '/img/Home-4-4.jpg'} alt="Home-4-4"/>
          <div className="Home-4-step-description">
            <div className="Home-4-number">4</div>
            <p className="Home-4-step-text">Получіть свою купівлю з доставкою додому, до відділу Нової пошти, чи самовивозом із нашого магазину.</p>
          </div>
        </div>
      </div>
    );
}

export default StepAnimation;
