import React from 'react';

const Documentation = () => {
  return (
    <div className="doc__container--mainWrapper">
      <h1 className="doc__header--mainTitle">Документація з використання побутової техніки</h1>
      
      <section className="doc__section--fridges">
        <h2 className="doc__header--sectionTitle">Холодильники</h2>
        <p className="doc__text--bodyText">
          Після транспортування холодильника, перш ніж його вмикати, обов’язково дайте йому відстоятися не менше 6-8 годин, якщо він був перевезений вертикально. А якщо був перевезений горизонтально то цілих 16-24 години. Це потрібно, щоб дати охолоджувальній рідині стабілізуватися!
        </p>
        <p className="doc__text--bodyText">
          Холодильник має досягти кімнатної температури перед вмиканням!
        </p>
        <p className="doc__text--bodyText">
          Після вмикання, потрібно зачикати поки холодильник вийде на вказану температуру, і тільки потім заповнюйте його продуктами!
        </p>
      </section>
      
      <section className="doc__section--freezers">
        <h2 className="doc__header--sectionTitle">Морозилки</h2>
        <p className="doc__text--bodyText">
          Як і у випадку з холодильниками, після перевезення морозилку необхідно залишити в спокої на кілька годин (4-6 годин після вертикального перевезення, 12-24 години після горизонтального). Це допомагає уникнути пошкодження компресора.
        </p>
        <p className="doc__text--bodyText">
          Після ввімкнення морозилки, дайте їй час на досягнення необхідної температури (-18°C) перед тим, як розміщувати продукти всередині. Не заповнюйте морозилку понад норму, щоб забезпечити правильну циркуляцію холодного повітря.
        </p>
        <p className="doc__text--bodyText">
          Слідкуйте за регулярним розморожуванням та очищенням морозилки. Ущільнювачі дверей також повинні бути в хорошому стані, щоб уникнути витоків холодного повітря.
        </p>
      </section>
      
      <section className="doc__section--washingMachines">
        <h2 className="doc__header--sectionTitle">Пральні машини</h2>
        <p className="doc__text--bodyText">
          Перш ніж користуватися пральною машиною, перевірте, чи всі транспортні болти видалені. Не видалення цих болтів може призвести до пошкодження машини під час прання.
        </p>
        <p className="doc__text--bodyText">
          Завантаження барабана повинно відповідати рекомендаціям виробника. Перевантаження може призвести до погіршення якості прання та скорочення терміну служби машини. Використовуйте правильний тип миючого засобу відповідно до типу тканини та рівня забруднення.
        </p>
        <p className="doc__text--bodyText">
          Слідкуйте за чистотою фільтрів і проводьте регулярне очищення машини для уникнення неприємних запахів та накопичення бруду.
        </p>
      </section>
      
      <section className="doc__section--dryers">
        <h2 className="doc__header--sectionTitle">Сушильні машини</h2>
        <p className="doc__text--bodyText">
          Перед сушінням переконайтесь, що білизна добре віджата, це знижує навантаження на сушильну машину та скорочує час сушіння. Не перевантажуйте сушильну машину, щоб уникнути пошкодження одягу та техніки.
        </p>
        <p className="doc__text--bodyText">
          Регулярно очищайте фільтри від ворсу для покращення ефективності роботи машини і уникнення пожежі. Слідкуйте за тим, щоб вентиляційний отвір не був заблокований.
        </p>
        <p className="doc__text--bodyText">
          Використовуйте спеціальні програми сушіння для делікатних тканин. Не сушіть взуття та інші тверді предмети, які можуть пошкодити барабан машини.
        </p>
      </section>
      
      <section className="doc__section--cookingSurfaces">
        <h2 className="doc__header--sectionTitle">Поверхні для готування</h2>
        <p className="doc__text--bodyText">
          Перед використанням, переконайтесь, що поверхні чисті та не мають залишків старої їжі або жиру. Використовуйте посуд, який підходить для вашої поверхні (газової, індукційної або електричної). Наприклад, для індукційних плит використовуйте посуд із магнітним дном.
        </p>
        <p className="doc__text--bodyText">
          Не залишайте плиту без нагляду під час готування, особливо якщо використовуєте масло або жир. Якщо щось розлилося, негайно вимкніть плиту і очистіть поверхню після охолодження.
        </p>
        <p className="doc__text--bodyText">
          Слідкуйте за тим, щоб вентиляція працювала належним чином, і виводила пари з кухні. Регулярно очищайте витяжку і фільтри.
        </p>
      </section>
      
      <section className="doc__section--ovens">
        <h2 className="doc__header--sectionTitle">Духовки</h2>
        <p className="doc__text--bodyText">
          Завжди попередньо розігрівайте духовку до необхідної температури перед тим, як поставити страву всередину. Це забезпечує рівномірне приготування їжі.
        </p>
        <p className="doc__text--bodyText">
          Використовуйте посуд, призначений для використання в духовках, щоб уникнути тріщин або розплавлення. Не ставте посуд, який щойно був у холодильнику, безпосередньо в гарячу духовку, щоб уникнути термічного шоку.
        </p>
        <p className="doc__text--bodyText">
          Регулярно очищайте духовку від залишків їжі та жиру, щоб уникнути накопичення нагару, що може вплинути на роботу духовки і призвести до неприємного запаху.
        </p>
      </section>
      
      <section className="doc__section--transportation">
        <h2 className="doc__header--sectionTitle">Перевезення та встановлення техніки</h2>
        <p className="doc__text--bodyText">
          При перевезенні техніки переконайтесь, що вона добре закріплена для уникнення пошкоджень. Використовуйте спеціальні ремені та амортизаційні матеріали, щоб зменшити ризик пошкодження під час транспортування.
        </p>
        <p className="doc__text--bodyText">
          Під час заносу великої техніки, зокрема холодильників і пральних машин, намагайтесь уникати різких ударів та подряпин, які можуть пошкодити зовнішні та внутрішні компоненти.
        </p>
        <p className="doc__text--bodyText">
          Після транспортування не підключайте техніку до електромережі одразу. Дайте їй можливість адаптуватися до кімнатної температури (особливо це стосується холодильників і морозилок).
        </p>
      </section>
      
      <section className="doc__section--electricIssues">
        <h2 className="doc__header--sectionTitle">Електричні питання</h2>
        <p className="doc__text--bodyText">
          У разі частих перепадів напруги, настійно рекомендується використовувати стабілізатори напруги для захисту вашої техніки. Високі або низькі напруги можуть призвести до виходу з ладу електронних компонентів або навіть до повного виходу техніки з ладу.
        </p>
        <p className="doc__text--bodyText">
          Не підключайте побутову техніку до однієї розетки разом із іншими потужними електроприладами. Це може спричинити перевантаження електромережі, що також підвищує ризик виходу з ладу.
        </p>
        <p className="doc__text--bodyText">
          Регулярно перевіряйте стан електричних проводів та штепсельних вилок. Якщо помітите пошкодження, негайно зверніться до фахівця. Самостійне ремонтування може бути небезпечним.
        </p>
      </section>
      
      <section className="doc__section--userResponsibility">
        <h2 className="doc__header--sectionTitle">Відповідальність користувача</h2>
        <p className="doc__text--bodyText">
          Магазин не несе відповідальності за пошкодження техніки внаслідок неправильного транспортування, встановлення або використання. Користувач зобов'язаний дотримуватись інструкцій з експлуатації, які додаються до кожного пристрою.
        </p>
        <p className="doc__text--bodyText">
          Будь-які гарантійні зобов’язання анулюються в разі втручання в електричні чи механічні компоненти техніки третіми особами або самостійного ремонту.
        </p>
        <p className="doc__text--bodyText">
          Якщо у вас виникли питання щодо встановлення або експлуатації техніки, звертайтеся до кваліфікованих спеціалістів. Уникайте самостійного ремонту, який може призвести до додаткових пошкоджень.
        </p>
      </section>
    </div>
  );
};

export default Documentation;
