import React, { useState } from 'react';

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    { text: '<strong>EUROSTOCK</strong> - Один з найбільших магазинів вживаної та стоковї побутової техніки з ЄС в Києві та Україні! Ми пропонуємо широкий вибір сучасної техніки для Вашого дому, за  максимально доступними цінами! Відвідайте EUROSTOCK за адресою <a class="visible-link" href="https://maps.app.goo.gl/kMsAThnLwfbUkPXc7">М.Київ, Просп.Берестейський 67, Корпус D</a>', image: process.env.PUBLIC_URL + '/img/Home-2-1.jpg' },
    { text: '<strong>НАША МЕТА</strong> - Забезпечити ваш спокій та комфорт, пропонуючи надійну та сучасну побутову техніку. Ми гарантуємо якість кожного товару, надаємо професійні консультації, швидку доставку та якісний монтаж. Довіртеся нам, і ми подбаємо про всі ваші потреби!', image: process.env.PUBLIC_URL + '/img/Home-2-2.jpg' },
    { text: '<strong>Програма Trade-in</strong> - Обмінюй стару техніку на знижки до 30% на нову! Надайте фото вашої техніки нашому <a class="visible-link" href="https://t.me/eurostock_nt">Менеджеру</a>, і дізнайтесь про ймовірність обміну, і процент скидки! <p class="comment-box">Будь ласка, пропонуйте лише гідні варіанти!</p>' , image: process.env.PUBLIC_URL + '/img/Home-2-3.jpg' },
    {
      text: '<strong>Правила Експлуатації</strong> - Правильне використання техніки - запорука її довговічності та вашого комфорту. Будь ласка перед використанням побутової техніки ознайомтесь з <a class="visible-link" href="/Exploitation">правилами і рекомендаціями по її експлуатації</a>, для запобігання поломок та втрати гарантії!',
      image: process.env.PUBLIC_URL + '/img/Home-2-4.jpg'
    }
    
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <div className="slider">
      <button onClick={prevSlide} className="prev-slide">&#9664;</button>
      <div className="slide">
        <img src={slides[currentSlide].image} alt={slides[currentSlide].text} />
        <div className="text-overlay">
          <p dangerouslySetInnerHTML={{ __html: slides[currentSlide].text }}></p>
        </div>
      </div>
      <button onClick={nextSlide} className="next-slide">&#9654;</button>
    </div>
  );
};

export default Slider;