import React, { useState } from 'react';

const CustomBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleHideBanner = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="banner-container">
          <div className="banner-content">
            <button className="close-btn" onClick={handleHideBanner}>
              <span className="close-icon">&#10006;</span>
            </button>
            <img src={process.env.PUBLIC_URL + '/img/Logo/6.png'} alt="Home-1-1" className="banner-image" />
            <p className="banner-text">Слідкуй за новими надходженнями і скидками в наших соцмережах!</p>
            <a href="https://invite.viber.com/?g2=AQA2C1s6Y3JXi05lEkuGFQ0QzpJezOPIhhvxVHcgb2MPplc8QbwmUDbV8Grpsf0K" className="banner-button">Viber</a>
            <a href="https://t.me/eurostockkyiv" className="banner-button">Telegram</a>
            <a href="https://www.instagram.com/eurostock_nt" className="banner-button">Instagram</a>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomBanner;

