import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FaCartShopping, FaBars } from 'react-icons/fa6';
import Home from './pages/Home';
import Catalog from './pages/Catalog';
import About from './pages/About';
import Service from './pages/Service';
import Contacts from './pages/Contacts';
import Exploitation from './pages/Exploitation'
import OrderForm from './OrderForm';
import Order from './Order';
import CustomBanner from './CustomBanner';
import NoInfo from './ProductsPages/NoInfo';
import Liebherr1863 from './ProductsPages/Liebherr1863';
import Samsung1111 from './ProductsPages/Samsung1111';

export default function Header(props) {
  const [cartOpen, setCartOpen] = useState(false);
  const [orderFormOpen, setOrderFormOpen] = useState(false);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);


  const handleOrderSubmit = (formData) => {
    console.log('Form data:', formData);
    setOrderFormOpen(false);
  };

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const toggleBanner = () => {
    setBannerVisible(!bannerVisible);
  };
  

  const showOrders = (orders) => {
    let sum = orders.reduce((acc, el) => acc + Number.parseFloat(el.price), 0);
    return (
      <div>
        {orders.map(el => (
          <Order onDelete={props.onDelete} key={el.id} item={el} />
        ))}
        <p className='sum'>Сума: {new Intl.NumberFormat().format(sum)}грн</p>
        <button onClick={() => setOrderFormOpen(true)} className="create-order">Замовити</button>
      </div>
    );
  };

  const showNothing = () => (
    <div className='no-orders'>
      <h2>Кошик - пустий</h2>
    </div>
  );

  return (
    <Router>
      <header className='header'>
        <div className='container'>
          <div className='header-inner'>
            <nav className="header-links">
              <FaBars className="burger-icon" onClick={toggleBurgerMenu} />
              <ul className={`burger-menu ${burgerMenuOpen ? 'active' : ''}`}>
                <li><Link to="/home" className="header-link">Головна</Link></li>
                <li><Link to="/catalog" className="header-link">Каталог</Link></li>
                <li><Link to="/service" className="header-link">Сервіс</Link></li>
                <li><Link to="/contacts" className="header-link">Контакти</Link></li>
                <li><Link to="/about" className="header-link">Про нас</Link></li>
              </ul>
            </nav>
            <div className="header-logo">EUROSTOCK</div>

            <FaCartShopping onClick={() => setCartOpen(!cartOpen)} className={`shop-cart-button ${cartOpen && 'active'}`} />

            {cartOpen && (
              <div className='shop-cart'>
                {props.orders.length > 0 ? showOrders(props.orders) : showNothing()}
              </div>
            )}
          </div>
        </div>
        <div className='header-2'>
          <div className='container'>
            <a className="header-2-link left" href="https://t.me/eurostock_nt">Онлайн-ассистент</a>
            <div className="header-2-1">
              <input type="text" id="search-input" placeholder="Пошук..." />
              <Link to="/catalog">
                <img src={process.env.PUBLIC_URL + '/img/header-2-1.png'} alt="Search" className="header-2-1-img" />
              </Link>
            </div>
            <a className="header-2-link right" onClick={toggleBanner}>Соцмережі</a>
          </div>
        </div>
      </header>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/about" element={<About />} />
        <Route path="/Exploitation" element={<Exploitation />} />
        <Route path="/NoInfo" element={<NoInfo />} />
        <Route path="/Liebherr1863" element={<Liebherr1863 />} />
        <Route path="/Samsung1111" element={<Samsung1111 />} />
      </Routes>

      {orderFormOpen && (
        <div className='modal'>
          <div className='modal-content'>
            <span className='close' onClick={() => setOrderFormOpen(false)}>&times;</span>
            <OrderForm onSubmit={handleOrderSubmit} orders={props.orders} />
          </div>
        </div>
      )}

      {bannerVisible && <CustomBanner />}
    </Router>
  );
}

