import React, { useState, useEffect } from 'react';

const CheckmarkAnimation = ({ show }) => {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 2000);
    }
  }, [show]);

  return (
    <div className={`checkmark-animation ${isVisible ? 'visible' : ''}`}>
      <div className="checkmark">
        <div className="checkmark__stem"></div>
        <div className="checkmark__kick"></div>
      </div>
    </div>
  );
};

export default CheckmarkAnimation;



