import React, { useState } from 'react';

function FAQSection() {
  // Ваші дані для FAQ, наприклад, список питань та відповідей
  const faqData = [
    {
      question: 'Чи можна відслідковувати наявність товару онлайн?',
      answer: 'Так, Ви можете відстежувати наявність товару онлайн в каталозі цього сайту або в наших соцмережах. Додатково, для швидкого зворотнього зв\'язку та консультацій є сторінка <a class="visible-link" href="Contacts.js">Контакти</a> нашого веб-сайту, а також <a class="visible-link" href="https://t.me/eurostock_nt">Онлайн-ассистент</a>, який завжди готовий вам допомогти у режимі реального часу.',
    },
    {
      question: 'Як швидко Ви робите доставку?',
      answer: 'Ми здійснюємо відправлення товарів в цей день або наступний для замовлень, оформлених в Києві та області. Для інших регіонів термін обробки та відправлення становить 1-3 робочих дні. Якщо залишились питання - звертайтесь до нашого <a class="visible-link" href="https://t.me/eurostock_nt">Онлайн-ассистента',
    },
    {
     question: 'Чи є у Вас програма обміну старої техніки на скидки',
     answer: 'Так, ми маємо програму Trade-in, яка дозволяє клієнтам здати стару техніку та отримати знижку на нову. Деталі та умови можна дізнатись, звернувшись до <a class="visible-link" href="https://t.me/eurostock_nt">Менеджера</a>.',
    },
    {
     question: 'Чи можна отримати знижку на велике замовлення?',
     answer: 'Так, ми готові обговорити умови та можливості індивідуальних знижок для великих замовлень. З пропозиціями звертайтеся до нашого <a class="visible-link" href="https://t.me/eurostock_nt">Менеджера</a>.',
    },
    {
     question: 'Як оплатити покупку у Вас?',
     answer: 'Ми приймаємо оплату готівкою, коли Ви отримуєте товар. Також у нас є можливість оплати банківським переказом, коли ми надаємо Вам деталі для оплати через банк. Обирайте той спосіб, який Вам зручний!',
    },
    {
     question: 'Де знаходиться Ваш магазин?',
     answer: 'Поки що наш магазин розташований лише в Києві. Завітайте до EUROSTOCK за адресою: <a class="visible-link" href="https://maps.app.goo.gl/pCUAjmjL5m9cBwXF7">М.Київ, Просп.Берестейський 67, Корпус D.</a> Наш магазин пропонує широкий вибір вживаної та стокової побутової техніки високої якості і за доступними цінами.',
    },
    {
     question: 'Як часто Ви оновлюєте асортимент продукції?',
     answer: 'Ми регулярно оновлюємо асортимент, додаючи нові та сучасні моделі. Слідкуйте за нашими новинками на сторінці <a class="visible-link" href="catalog.js">Каталогу</a> сайту або приєднуйтесь до групи <a class="visible-link" href="https://invite.viber.com/?g2=AQA2C1s6Y3JXi05lEkuGFQ0QzpJezOPIhhvxVHcgb2MPplc8QbwmUDbV8Grpsf0K">Viber</a> чи <a class="visible-link" href="https://t.me/eurostockkyiv">Telegram</a>, щоб бути в курсі останніх оновлень.',
    },
    {
     question: 'Як отримати інформацію про акції та знижки?',
     answer: 'Ви можете слідкувати за акціями та знижками в наших групах <a class="visible-link" href="https://invite.viber.com/?g2=AQA2C1s6Y3JXi05lEkuGFQ0QzpJezOPIhhvxVHcgb2MPplc8QbwmUDbV8Grpsf0K">Viber</a> та <a class="visible-link" href="https://t.me/eurostock_kyiv">Telegram</a>.',
    },
  ];

  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <div className="Home-8">
      <h1 className='titles'>Відповіді на FAQ</h1>
      <ul>
        {faqData.map((item, index) => (
          <li key={index}>
            <button className="question" onClick={() => toggleQuestion(index)}>
              {item.question}
              {openQuestion === index ? '▲' : '▼'} {/* Змінюємо значок залежно від стану питання */}
            </button>
            {openQuestion === index && (
              <div dangerouslySetInnerHTML={{ __html: item.answer }} className="answer" />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FAQSection;


