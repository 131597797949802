import React from 'react';

const NoProductInfo = () => {
  return (
    <div className="no-product-info-container">
      <div className="no-product-info-message">
        Інформації про цей товар ще немає!
      </div>
      <div className="additional-info">
        <p>Зверніться до онлайн ассистента!</p>
        <div className="loading-dots">
          <span>.</span><span>.</span><span>.</span>
        </div>
      </div>
    </div>
  );
};

export default NoProductInfo;

