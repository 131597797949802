import React, { useState } from 'react';

const OrderForm = ({ onSubmit, orders }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    orderIds: orders.map(order => order.id)
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/meqbbvej', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {

        console.log('Form submitted successfully!');
        onSubmit(formData); 
      } else {
        console.error('Form submission failed!');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="order-form">
      <label>
        Ім'я:
        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
      </label>
      <label>
        Прізвище:
        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
      </label>
      <label>
        Номер телефону:
        <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
      </label>
      <label>
        Електронна пошта:
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
      </label>
      <input type="hidden" name="orderIds" value={JSON.stringify(formData.orderIds)} />
      <button type="submit" className="submit-button">Відправити замовлення</button>
    </form>
  );
};

export default OrderForm;

