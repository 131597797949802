import React from 'react';

const Loader = () => (
  <div className="loader-container">
    <div className="loader">
      EUROSTOCK
    </div>
  </div>
);

export default Loader;

