export function submitForm() {
    var form = document.getElementById('contactForm');
    var formData = new FormData(form);

    if (!formData.get('name') || !formData.get('email') || !formData.get('message')) {
        alert('Будь ласка, заповніть всі обов\'язкові поля');
        return;
    }

    var xhr = new XMLHttpRequest();
    xhr.open('POST', form.action, true);
    xhr.setRequestHeader('Accept', 'application/json');

    xhr.onreadystatechange = function () {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;

        if (xhr.status === 200) {
            alert('Ваше повідомлення успішно відправлено!');
            form.reset();
        } else {
            alert('Сталася помилка відправлення повідомлення. Будь ласка, спробуйте ще раз або скористайтесь іншим способом зв\'язку.');
        }
    };

    xhr.send(formData);
}
