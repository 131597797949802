import React, { Suspense, lazy } from 'react';

const Items = lazy(() => import('./Items'));

const LazyItems = (props) => (
  <Suspense fallback={<div>Обновлення товару...</div>}>
    <Items {...props} />
  </Suspense>
);

export default LazyItems;
