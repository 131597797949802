import React, { Component } from 'react';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [
        { key: 'hot', name: 'Hot' },
        { key: 'tv', name: 'Smart-TV' },
        { key: 'fridge', name: 'Холодильники' },
        { key: 'sidebyside', name: 'Сайди' },
        { key: 'mini', name: 'Міні-холодильники' },
        { key: 'freezer', name: 'Морозилки' },
        { key: 'washer', name: 'Пралки' },
        { key: 'verticale', name: 'Вертикалки' },
        { key: 'dryer', name: 'Сушки' },
        { key: '2in1', name: 'Сушка&Пралка' },
        { key: 'dishwasher', name: 'Посудомийки' },
        { key: 'microwave', name: 'Мікрохвильовки' },
        { key: 'oven', name: 'Духовки' },
        { key: 'stove', name: 'Плити' },
        { key: 'hob', name: 'Поверхні' },
      ]
    };
  }

  render() {
    return (
      <div className='categories'>
        {this.state.categories.map((el, index) => (
          <div onClick={() => this.props.chooseCategory(el.key)} key={index}>{el.name}</div>
        ))}
      </div>
    );
  }
}

export default Categories;
